Array.prototype.diff = function (a) {
  return this.filter(function (i) {
    return !(a.indexOf(i) > -1);
  });
};

$(document).on("turbolinks:load", function () {
  // -- Pricing scroll to -- //
  $("#pricing-link").click(function () {
    $("body").scrollTo("#pricing", { offsetTop: "-10" });
  });

  // -- Hero slider -- //
  // $(function () {
  var $slides = $("#hero .slides .slide");
  var $activeSlide = $("#hero .slide.active");
  var actualIndex = $activeSlide.data("index");
  var swiping = false;
  var interval;
  function updateSlides(index) {
    var $activeSlide = $("#hero .slide.active");
    var $nextSlide = $slides.eq(index);
    $activeSlide.fadeOut();
    $nextSlide.addClass("next").fadeIn();
    setTimeout(function () {
      $slides.removeClass("next").removeClass("active");
      $nextSlide.addClass("active");
      $activeSlide.removeAttr("style");
      swiping = false;
    }, 1000);
  }
  interval = setInterval(function () {
    if (swiping) {
      return;
    }
    swiping = true;
    actualIndex++;
    if (actualIndex >= $slides.length) {
      actualIndex = 0;
    }
    updateSlides(actualIndex);
  }, 7500);
});

// -- Demo Modal -- //
var demoForm = {
  init: function () {
    var $modal = $("#demo-form");
    var $form = $modal.find("form");
    var $requiredFields = $modal.find(".required");
    var $submit = $modal.find(".btn-submit");
    var $success = $modal.find(".alert");
    var $modalCloseLink = $modal.find(".close-modal");
    var $modalBody = $modal.find(".modal-body");
    var $modalFooter = $modal.find(".modal-footer");

    $submit.on("click", function (e) {
      e.preventDefault();
      $submit.addClass("disabled");
      var hasError = false;
      $.each($modal.find(".required"), function (index, value) {
        if ($(this).val().length == 0) {
          $(this).closest(".form-group").addClass("has-error");
          hasError = true;
        } else {
          $(this).closest(".form-group").removeClass("has-error");
        }
      });
      if (hasError) {
        $submit.removeClass("disabled");
      } else {
        // ajax
        request = $.ajax({
          url: $form.attr("action"),
          type: "post",
          data: $form.serialize(),
        });

        // success
        request.done(function (data, textStatus, jqXHR) {
          $success.fadeIn();
          $modalBody.hide();
          $modalFooter.css("border-top", "none");
          $submit.hide();
          $form[0].reset();
        });

        // fail
        request.fail(function (jqXHR, textStatus, errorThrown) {
          console.log(errorThrown);
        });

        // either way
        request.always(function () {
          $submit.removeClass("disabled");
        });
      }
    });

    $modalCloseLink.on("click", function (e) {
      e.preventDefault();
      $modal.modal("hide");
      $success.hide();
      $submit.removeClass("disabled");
      $form
        .find(":input")
        .not(":button, :submit, :reset, :hidden")
        .val("")
        .removeAttr("checked")
        .removeAttr("selected");
    });
  },
};

// -- Random swapping gift card tiles -- //
var tiles = {
  init: function () {
    tiles.elements = {
      windowIsVisible: true,
      $tilesWrapper: $("#tiles"),
      $slots: ".slot",
      slotClass: "slot",
      pathToJson: "/home/products/featured_gift_cards.json",
      numSlots: null,
      dataArr: null,
      activeArr: null,
      inactiveArr: null,
      orderArr: null,
      orderIndex: 0,
    };
    tiles.bindEvents();
    tiles.initSlots();
    window.setInterval(function () {
      tiles.imageSwap();
    }, 1800);
  },

  bindEvents: function () {
    $(window).blur(function () {
      tiles.elements.windowIsVisible = false;
    });
    $(window).focus(function () {
      tiles.elements.windowIsVisible = true;
    });
  },

  imageSwap: function () {
    //console.log("window is visible: " + tiles.elements.windowIsVisible);

    if (!tiles.elements.windowIsVisible) return;

    // pick the next slot
    var slotIndex = tiles.elements.orderArr[tiles.elements.orderIndex];
    tiles.elements.orderIndex++;
    tiles.elements.orderIndex =
      tiles.elements.orderIndex >= tiles.elements.numSlots
        ? 0
        : tiles.elements.orderIndex;
    var $slot = $("." + tiles.elements.slotClass + slotIndex);

    // find the current item in the slot
    var currentIndex = tiles.elements.activeArr.indexOf($slot.data("index"));

    // get the next item from the end of inactive
    var newIndex = tiles.elements.inactiveArr.pop();

    // add the current item to the beginning of inactive
    tiles.elements.inactiveArr.unshift(currentIndex);

    // load the next item into active index
    tiles.elements.activeArr[currentIndex] = newIndex;

    // and load the new card
    $link = $slot.find("a");
    $img = $slot.find("img");
    $img.fadeOut("fast", function () {
      $slot.data("index", newIndex);
      $link.attr("href", tiles.elements.dataArr[newIndex].url);
      $(this).addClass("animated flipInY");
      $(this).attr("src", tiles.elements.dataArr[newIndex].file);
      $(this).fadeIn("slow", function () {
        $(this).removeClass("animated flipInY");
      });
    });
  },

  initSlots: function () {
    var shuffleArray = function (array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    };
    var getRandomSubarray = function (arr, size) {
      var shuffled = shuffleArray(arr),
        i = arr.length,
        min = i - size;
      return shuffled.slice(min);
    };
    var getRange = function (i) {
      return i ? getRange(i - 1).concat(i) : [];
    };
    tiles.loadDataArr(); // load json with attrs url, file
    //tiles.elements.numSlots = tiles.elements.$tilesWrapper.find(tiles.elements.$slots+':visible').length; // number of slots on page
    tiles.elements.numSlots = tiles.elements.$tilesWrapper.find(
      tiles.elements.$slots
    ).length; // number of slots on page
    var tmpArr = getRange(tiles.elements.dataArr.length - 1); // temp arr to take diff later
    tiles.elements.activeArr = getRandomSubarray(
      tmpArr,
      tiles.elements.numSlots
    ); // pick the active indexes
    tiles.elements.inactiveArr = shuffleArray(
      tmpArr.diff(tiles.elements.activeArr)
    ); // use tmp to get inactive diff
    $.each(tiles.elements.activeArr, function (index, value) {
      // load active to slots
      var url = tiles.elements.dataArr[value].url;
      var file = tiles.elements.dataArr[value].file;
      var $slot = $("." + tiles.elements.slotClass + (index + 1));
      $slot.attr("data-index", value);
      $slot.append(
        "<a href='" +
          url +
          "' target='_blank'><img src='" +
          file +
          "' class='img-fluid'></a>"
      );
    });
    tiles.elements.orderArr = shuffleArray(getRange(tiles.elements.numSlots)); // pick a random order to cycle through for swapping
  },

  loadDataArr: function () {
    tiles.elements.dataArr = (function () {
      var json = null;
      $.ajax({
        async: false,
        global: false,
        url: tiles.elements.pathToJson,
        dataType: "json",
        success: function (data) {
          json = data;
        },
      });
      return json;
    })();
  },

  pickNextSlot: function () {},
}; // end tiles

$(document).on("turbolinks:load", tiles.init);
$(document).on("turbolinks:load", demoForm.init);
//});

$.fn.scrollTo = function (target, options, callback) {
  if (typeof options == "function" && arguments.length == 2) {
    callback = options;
    options = target;
  }
  var settings = $.extend(
    {
      scrollTarget: target,
      offsetTop: 50,
      duration: 500,
      easing: "swing",
    },
    options
  );
  return this.each(function () {
    var scrollPane = $(this);
    var scrollTarget =
      typeof settings.scrollTarget == "number"
        ? settings.scrollTarget
        : $(settings.scrollTarget);
    var scrollY =
      typeof scrollTarget == "number"
        ? scrollTarget
        : scrollTarget.offset().top +
          scrollPane.scrollTop() -
          parseInt(settings.offsetTop);
    scrollPane.animate(
      { scrollTop: scrollY },
      parseInt(settings.duration),
      settings.easing,
      function () {
        if (typeof callback == "function") {
          callback.call(this);
        }
      }
    );
  });
};

window.requestDemoRecaptchaCallback = function () {
  $("#request-demo-submit").removeAttr("disabled");
};
